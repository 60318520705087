<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="false" location="bottom">
      <template v-slot:activator="{ props }">
        <div class="menu mr-4" v-bind="props">
          <v-btn v-bind="props" variant="text" rounded="lg">
            My saved lists
            <v-icon end icon="mdi-chevron-down"></v-icon>
          </v-btn>
        </div>
      </template>

      <v-card width="425" elevation="1" rounded="lg" style="overflow:hidden;">
        <v-row no-gutters align="center" justify="space-between" class="mt-3 mx-6">
          <p class="text-h6">
            My saved lists
          </p>
        </v-row>

        <div class="tabContainer pl-6 mt-1 pr-1">
          <v-tabs v-model="tab" color="#101828" grow>
            <v-tab :value="1" class="domain-tab" min-width="75">
              All
              <div class="count">
                {{ listStore.lists.length }}
              </div>
            </v-tab>
            <v-tab :value="2" class="domain-tab" min-width="75">
              Created by you
              <div class="count">
                {{ listStore.lists.filter(x => x.created_by === user.user_id).length }}
              </div>
            </v-tab>
            <v-tab :value="3" class="domain-tab" min-width="75">
              Shared with you
              <div class="count">
                {{ listStore.lists.filter(x => x.created_by !== user.user_id).length }}
              </div>
            </v-tab>
          </v-tabs>
        </div>

        <perfect-scrollbar style="max-height:75vh">
          <div class="itemContainer" v-for="item in lists"
            :class="{ active: route.params.listId === String(item.list_id) }"
            @click="trackEvent({ event: '¤ Saved lists', name: 'View', value: '' }); navigateTo('/lists/' + item.list_id)">

            <p class="font-weight-bold">
              {{ item.list_name }}
            </p>

            <div class="d-flex justify-space-between align-end">
              <div>
                <p class="mt-1">
                  Created by {{ item.created_by === user.user_id ? 'you' : 'other' }},
                </p>

                <p>
                  {{ formatTimestamp(item.created_at, "MMMM do 'at' h:mm a") }}
                </p>
              </div>

              <p v-if="route.params.listId === String(item.list_id)">
                Viewing
              </p>

              <p v-else class="view">
                <NuxtLink>
                  View
                </NuxtLink>
              </p>
            </div>

          </div>

          <div v-if="lists.length < 1" class="itemContainer">
            <p>No lists</p>
          </div>
        </perfect-scrollbar>
      </v-card>
    </v-menu>
  </div>
</template>

<script setup>
import { useSavedListStore } from '~/stores/savedLists';
import { useUserStore } from '~/stores/user';

const listStore = useSavedListStore()
const user = useUserStore()
const route = useRoute()

const menu = ref(false)
const tab = ref(1)

const lists = computed(() => {
  if (tab.value === 2) {
    return listStore.lists.filter(x => x.created_by === user.user_id)
  }

  else if (tab.value === 3) {
    return listStore.lists.filter(x => x.created_by !== user.user_id)
  }

  return listStore.lists
})

listStore.getLists()

watch(tab, () => {
  trackEvent({
    event: '¤ Saved lists',
    name: 'Tab',
    value: tab.value === 1 ? 'All' : tab.value === 2 ? 'Created' : 'Shared'
  })
})

</script>

<style lang="scss" scoped>
.tabContainer {
  border-bottom: 1px solid $color-gray-200;
}

.menu {
  position: relative;
}

.itemContainer {
  padding: 16px 24px;
  border-bottom: 1px solid $color-gray-200;
  cursor: pointer;

  &.active,
  &:hover {
    background-color: $color-gray-50;
  }

  .view {
    color: $color-gray-900;
    font-size: 12px;
    font-weight: bold;
    text-decoration-line: underline;
  }
}
</style>