<template>
  <div class="container">

    <div class="content">
      <div class="d-flex align-center h-100">
        <img src="~/assets/logos/tembi.png" alt="" />

        <div class="mr-4 ml-4" style="height:16px; width: 1px; background-color: #98A2B3;" />

        <p class="text-h6 font-weight-bolder mr-8">
          EIP
        </p>

        <NuxtLink v-for="item in menuItems" :to="item.path" class="menuItem">
          {{ item.name }}
        </NuxtLink>

        <NuxtLink to="/analytics" class="menuItem" v-if="user.modules.includes('analytics')">
          Analytics (beta)
        </NuxtLink>

        <NuxtLink to="/users" class="menuItem" v-if="user.admin">
          My team
        </NuxtLink>
      </div>

      <v-row justify="end" align="center" no-gutters>
        <HeaderSearchCompany />

        <HeaderSavedLists />

        <HeaderNotifications />

        <HeaderProfile />

        <HeaderSelectCountry />
      </v-row>
    </div>

  </div>
</template>

<script setup>
import { useUserStore } from '~/stores/user';

const user = useUserStore()

const menuItems = [
  {
    name: 'Webshops',
    path: '/webshops/list'
  },
]

</script>

<style scoped lang="scss">
.container {
  width: 100%;
  padding: 0 20px 0 32px;
  height: 72px;
  border-bottom: 1px solid $color-gray-100;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  background: white;
  z-index: 4;

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .menuItem {
    font-size: 16px;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin: 0px 10px;
  }
}
</style>