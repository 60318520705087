<template>
  <div v-if="countries.length > 1">
    <v-menu v-model="menu" :close-on-content-click="true" location="bottom">
      <template v-slot:activator="{ props }">
        <div class="menu ml-2" v-bind="props">
          <v-btn v-bind="props" variant="text" rounded="lg" :loading="switchingCountry">
            <country-flag :country="webshops.country_code" size='small' />
            {{ countryCodes[webshops.country_code] }}
          </v-btn>
        </div>
      </template>

      <v-card min-width="220" elevation="1" rounded="lg" style="overflow:hidden;">
        <v-list @update:selected="handleSelect">
          <v-list-item v-for="(item, i) in countries" :key="i" :value="item">
            <div class="d-flex align-end">
              <country-flag :country="item" size='normal' />

              <p class="ml-4 font-weight-bold">
                {{ countryCodes[item] }}
              </p>
            </div>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script setup>
import { useCustomerStore } from '~/stores/customer';
import { useUserStore } from '~/stores/user';
import { useWebshopsStore } from '~/stores/webshops';

const customer = useCustomerStore()
const webshops = useWebshopsStore()
const user = useUserStore()

const menu = ref(false)
const switchingCountry = ref(false)

const countries = computed(() => {
  return [...new Set(customer.access.map(obj => obj.country_code))]
})

async function handleSelect(val) {
  const item = customer.access.find(x => x.country_code === val[0])

  webshops.country_code = item.country_code

  switchingCountry.value = true
  await user.updateDefaultCountry(item.country_code)

  window.location.reload()
}

</script>

<style lang="scss" scoped>
.menu {
  position: relative;
}
</style>