<template>
  <v-app>
    <HeaderMain />
    <div>
      <slot />
    </div>
  </v-app>
</template>

<script setup>
</script>

<style></style>