<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="false" location="bottom"
      :on-update:model-value="onOpenNotification()">
      <template v-slot:activator="{ props }">
        <div class="iconContainer" v-bind="props">
          <div class="newNotifications" v-if="unreadNotifications > 0">
            {{ unreadNotifications }}
          </div>
          <v-btn icon="mdi-bell-outline" v-bind="props" size="small" elevation="0" class="btn"></v-btn>
        </div>
      </template>

      <v-card width="400" elevation="1" rounded="lg" style="overflow:hidden;">
        <v-row no-gutters align="center" justify="space-between" class="mt-3 mb-1 mx-5">
          <p class="text-h6">
            Notifications
          </p>
          <v-btn icon="mdi-cog-outline" elevation="0" size="" color="" @click="navigateTo('/profile?tab=Notification')" />
        </v-row>

        <div class="tabContainer">
          <v-tabs v-model="tab" color="#101828" grow stacked height="55">
            <v-tab :value="1" class="domain-tab">
              <div class="d-flex align-center">
                <div>
                  <div>
                    Checkout
                  </div>
                  <div>
                    Positions
                  </div>
                </div>
                <div class="countN">
                  {{ user.notifications.filter(x => !x.clicked && groups.checkout.includes(x.notification.type)).length }}
                </div>
              </div>
            </v-tab>
            <v-tab :value="2" class="domain-tab">
              <div class="d-flex align-center">
                <div>
                  <div>
                    New
                  </div>
                  <div>
                    Webshops
                  </div>
                </div>
                <div class="countN">
                  {{ user.notifications.filter(x => !x.clicked && groups.webshops.includes(x.notification.type)).length }}
                </div>
              </div>
            </v-tab>
            <v-tab :value="3" class="domain-tab">
              <div class="d-flex align-center">
                <div>
                  <div>
                    Other
                  </div>
                </div>
                <div class="countN">
                  {{ user.notifications.filter(x => !x.clicked && groups.other.includes(x.notification.type)).length }}
                </div>
              </div>
            </v-tab>
          </v-tabs>
        </div>

        <perfect-scrollbar style="max-height:75vh">
          <div class="itemContainer" v-for="item in filteredNotifications" :class="{ new: !item.clicked }">

            <div v-if="item.notification.type === 'webshops_added'" class="notification" @click="handleFilterClick(item)">
              <NotificationsWebshopsAdded :item="item" />
            </div>

            <div v-else-if="item.notification.type === 'list_invite'" class="notification"
              @click="user.clickNotification(item.notification_id)">
              <NotificationsListInvite :item="item" />
            </div>

            <div v-else-if="item.notification.type === 'domain_assigned'" class="notification"
              @click="user.clickNotification(item.notification_id)">
              <NotificationsDomainAssigned :item="item" />
            </div>

            <div v-else-if="item.notification.type === 'domain_comment'" class="notification"
              @click="handleCommentClick(item)">
              <NotificationsDomainComment :item="item" />
            </div>

            <div v-else-if="item.notification.type === 'domain_mention'" class="notification"
              @click="handleCommentClick(item)">
              <NotificationsDomainMention :item="item" />
            </div>

            <div v-else-if="item.notification.type === 'best_change'" class="notification"
              @click="handleDomainChangeClick(item)">
              <NotificationsBestChange :item="item" />
            </div>

            <div v-else-if="item.notification.type === 'shipping_methods_change'" class="notification"
              @click="handleDomainChangeClick(item)">
              <NotificationsShippingMethod :item="item" />
            </div>

            <div v-else-if="item.notification.type === 'rank_change'" class="notification"
              @click="handleDomainChangeClick(item)">
              <NotificationsRankChange :item="item" />
            </div>

            <div v-else-if="item.notification.type === 'new_providers'" class="notification"
              @click="handleDomainChangeClick(item)">
              <NotificationsNewProvider :item="item" />
            </div>

            <div v-else-if="item.notification.type === 'exporting_countries_change'" class="notification"
              @click="handleDomainChangeClick(item)">
              <NotificationsExportCountries :item="item" />
            </div>

            <div v-else-if="item.notification.type === 'return_address_change'" class="notification"
              @click="handleDomainChangeClick(item)">
              <NotificationsReturnAddress :item="item" />
            </div>
          </div>

          <div v-if="filteredNotifications.length < 1" class="itemContainer py-6 px-4">
            <p>No notifications</p>
          </div>

        </perfect-scrollbar>

        <div class="d-flex justify-space-between w-100">
          <v-btn variant="plain" @click="user.readAllNotifications">
            Mark all as read
          </v-btn>

          <v-btn variant="plain" @click="navigateTo('/notifications'); menu = false;">
            View all
          </v-btn>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script setup>
import { useUserStore } from '~/stores/user';
import { useWebshopsStore } from '~/stores/webshops';

const menu = ref(false)
const user = useUserStore()
const webshops = useWebshopsStore()

const tab = ref(1)

let groups = {
  checkout: ["rank_change", "best_change"],
  webshops: ["webshops_added"],
  other: ["list_invite", "domain_assigned", "domain_comment", "domain_mention",
    "shipping_methods_change", "new_providers", "exporting_countries_change", "return_address_change"]
}

const filteredNotifications = computed(() => {
  if (tab.value === 1) {
    return user.notifications.filter(x => groups.checkout.includes(x.notification.type))
  }

  else if (tab.value === 2) {
    return user.notifications.filter(x => groups.webshops.includes(x.notification.type))
  }

  else if (tab.value === 3) {
    return user.notifications.filter(x => groups.other.includes(x.notification.type))
  }

  return user.notifications
})

const unreadNotifications = computed(() => {
  return user.notifications.filter(x => !x.clicked).length
})

function onOpenNotification() {
  if (menu.value) {
    trackEvent({
      event: '¤ Notifications',
      name: 'Open',
    })
  }
}

function handleFilterClick(item) {
  user.clickNotification(item.notification_id)

  webshops.resetFilters()

  webshops.notification_id = item.notification_id
  webshops.getData()
  trackNotificationClick(item)
}

function handleCommentClick(item) {
  user.clickNotification(item.notification_id)

  webshops.resetFilters()
  webshops.filters.webshopUrlText = item.notification.name
  trackNotificationClick(item)
}

function handleDomainChangeClick(item) {
  user.clickNotification(item.notification_id)

  webshops.resetFilters()
  webshops.filters.webshopUrlText = item.notification.domain
  trackNotificationClick(item)
}

function trackNotificationClick(item) {
  trackEvent({
    event: '¤ Notifications',
    name: 'Click',
    value: item.notification.type
  })
}

</script>

<style lang="scss" scoped>
.tabContainer {
  border-bottom: 1px solid $color-gray-200;
}

.iconContainer {
  position: relative;

  .btn {
    border: 1px solid $color-gray-300;
  }

  .newNotifications {
    border-radius: 100px;
    padding: 0px 7px;
    background-color: $color-red-600;
    color: white;
    position: absolute;
    z-index: 99;
    right: -8px;
    top: -4px;
    border: 2px solid white;
    font-size: 12px;
    cursor: pointer;
  }
}

.itemContainer {
  &.new {
    background-color: $color-blue-25;
  }

  .notification {
    border-bottom: 1px solid $color-gray-200;
    padding: 16px;
    cursor: pointer;
  }
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 4px;
}

.new .notiCircle {
  width: 14px;
  height: 14px;
  border-radius: 14px;
  border: 3px solid white;
  position: absolute;
  bottom: -2px;
  right: -2px;
  background-color: $color-blue-500;
}

.countN {
  height: 20px;
  background-color: $color-gray-200;
  padding: 2px 6px;
  border-radius: 100px;
  margin-left: 8px;
  margin-right: 4px;
  font-size: 12px;
  font-weight: 700;
}
</style>