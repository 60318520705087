<template>
  <div class="searchBar mr-4" id="searchBar" v-bind="props">
    <v-text-field v-model="search" density="comfortable" rounded="lg" placeholder="Search for webshop or company"
      prepend-inner-icon="mdi-magnify" variant="outlined" hide-details name="xyz123" autocomplete="xyz123"
      @update:focused="handleFocus">

      <template v-slot:append-inner="{}">
        <v-progress-circular v-if="loading" indeterminate size="small" width="2" color="#32D594"></v-progress-circular>
      </template>
    </v-text-field>

    <v-card min-width="540" elevation="1" rounded="lg" class="menu" v-if="menu">
      <v-list v-if="search && items.length > 0">
        <v-list-item v-for="(item, i) in items" :key="i" @click="openPage(item)">
          <div class="d-flex py-2">
            <div class="favIcon mr-4">
              <img v-if="item?.favicon_url != 'None'" :src="item?.favicon_url"
                style="max-width: 48px; max-height: 48px;" />
            </div>

            <div>
              <p class="text-soft-header">
                {{ item?.domain }}
              </p>
              <p style="color:#475467;">
                {{ item?.name || '-' }}
                {{ item?.company_number ? `- ${item.company_number}` : '' }}
              </p>
            </div>
          </div>
        </v-list-item>
      </v-list>

      <div v-else-if="!loading && search && items.length < 1" class="missingData">
        <div class="circle">
          <v-icon icon="mdi-emoticon-sad-outline" size="large" color="#000" />
        </div>

        <div>
          <p class="mt-4 text-soft-header" style="text-align: center;">
            No results found
          </p>
          <p class="mt-2" style="text-align: center;">
            Please try a different search term or
          </p>
        </div>

        <div class="d-flex mt-4">
          <v-btn variant="outlined" class="slim bg-white ml-2"
            @click="navigateTo('https://knowledge.tembi.io', { external: true })">
            Contact us
          </v-btn>
        </div>
      </div>

      <div v-else-if="!search && searches.length > 0" class="">
        <p class="text-soft-header px-4 pt-4">
          Previous searches
        </p>
        <v-list>
          <v-list-item v-for="(item, i) in searches.slice(0, 3)" :key="i" @click="openPage(item)">
            <div class="d-flex py-2">
              <div class="favIcon mr-4">
                <img v-if="item?.favicon_url != 'None'" :src="item?.favicon_url"
                  style="max-width: 48px; max-height: 48px;" />
              </div>

              <div>
                <p class="text-soft-header">
                  {{ item?.domain }}
                </p>
                <p style="color:#475467;">
                  {{ item?.name || '-' }}
                  {{ item?.company_number ? `- ${item.company_number}` : '' }}
                </p>
              </div>
            </div>
          </v-list-item>
        </v-list>
      </div>

    </v-card>
  </div>
</template>

<script setup>
import { useWebshopsStore } from '~/stores/webshops';

const loading = ref(false)
const search = ref(null)
const items = ref([])
const menu = ref(false)
const searches = ref(window.localStorage.getItem('searches') ? JSON.parse(window.localStorage.getItem('searches')) : [])
const webshops = useWebshopsStore()

const debouncedWatch = debounce(() => {
  searchWebshops();
}, 300);

watch(search, () => {
  if (search.value) {
    loading.value = true
    debouncedWatch()
  }
})

async function searchWebshops() {
  const response = await handleGet({ url: `/frontend/domains/search?country=${webshops.country_code}&text=${search.value}` })

  loading.value = false
  items.value = response?.data || []
}

function openPage(item) {
  const previousSearches = window.localStorage.getItem('searches') ? JSON.parse(window.localStorage.getItem('searches')) : []
  window.localStorage.setItem('searches', JSON.stringify([item, ...previousSearches]))
  search.value = item.domain

  trackEvent({
    event: '¤ Webshop profile',
    name: 'Search',
    value: item.domain
  })

  navigateTo(`/webshops/profile/${item.domain_id}`)
}

function handleFocus(event) {
  if (event) { menu.value = true }

  else {
    setTimeout(() => {
      menu.value = false
    }, 150)
  }
}
</script>

<style lang="scss" scoped>
.searchBar {
  width: 540px;
  max-width: 25vw;

  .menu {
    position: absolute;
    max-height: 60vh;
    overflow-y: auto;
  }
}

.favIcon {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.missingData {
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  background-color: $color-gray-50;
  display: flex;
  flex-direction: column;
  align-items: center;

  .circle {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background-color: $color-gray-200;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>