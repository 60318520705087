<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="true" location="bottom">
      <template v-slot:activator="{ props }">
        <div class="menu ml-2" style="cursor: pointer;" v-bind="props">
          <ProfilePicture :user="user" :width="40" :height="40" />
        </div>
      </template>

      <v-card min-width="300" elevation="1" rounded="lg">
        <v-list density="comfortable">
          <v-list-item style="background-color: #FCFCFD;">
            <div class="d-flex py-2 align-center">
              <div class="mr-4">
                <ProfilePicture :user="user" :width="48" :height="48" />
              </div>

              <div>
                <p class="font-weight-bold">
                  {{ user.user_name }}
                </p>
                <p>
                  {{ user.email }}
                </p>
              </div>
            </div>
          </v-list-item>

          <v-list-item @click="navigateTo('/profile?tab=Profile')">
            <p class="font-weight-bold">
              Profile
            </p>
          </v-list-item>

          <v-list-item @click="navigateTo('/profile?tab=Notification')">
            <p class="font-weight-bold">
              Notification settings
            </p>
          </v-list-item>

          <v-list-item @click="navigateTo('/profile?tab=Webshops')">
            <p class="font-weight-bold">
              Monitored webshops
            </p>
          </v-list-item>

          <v-divider class="my-1" />

          <!-- <v-list-item @click="showChat">
            <p class="font-weight-bold">
              Chat with us
            </p>
          </v-list-item> -->

          <v-list-item @click="navigateTo('/profile?tab=Webshops')">
            <NuxtLink class="d-flex align-end" :to="'https://knowledge.tembi.io'" target="_blank">
              <p class="font-weight-bold" style="color:#000000DE">
                Knowledge base
              </p>
              <v-icon class="ml-1" icon="mdi-open-in-new" size="small" color="#000000DE"></v-icon>
            </NuxtLink>
          </v-list-item>

          <v-divider class="my-1" />

          <v-list-item @click="nuxtApp.$auth.logout()">
            <div class="d-flex align-center">
              <v-icon start icon="mdi-logout"></v-icon>

              <p class="font-weight-bold">
                Sign out
              </p>
            </div>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script setup>
import { useUserStore } from '~/stores/user';

const nuxtApp = useNuxtApp();
const user = useUserStore()

const menu = ref(false)

const resizeObserver = new ResizeObserver(entries => {
  for (const entry of entries) {
    if (entry.target.clientHeight < 100) {
      document.getElementById('hubspot-messages-iframe-container').style.visibility = 'hidden'
    } else {
      document.getElementById('hubspot-messages-iframe-container').style.visibility = 'visible'
    }
  }
});

function showChat() {
  window.HubSpotConversations.widget.open()
  resizeObserver.observe(document.getElementById('hubspot-messages-iframe-container'))
}
</script>

<style lang="scss" scoped>
.menu {
  position: relative;
}
</style>